import React from "react";
import uidesign from "../../assets/aboutimg/uidesign.webp";
import icon3 from "../../assets/images/icon3.webp";
import icon32 from "../../assets/images/icon32.webp";
import icon33 from "../../assets/images/icon33.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Uidesigncontent = () => {
  return (
    <div>
      <div>
        <div className="">
          <div className="aboutbanner mb-0 serban">
            <h4>Services</h4>
          </div>
          <div className="topcontent" id="servicepage">
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-7 my-auto">
                  <div className="bg-orange-content mb-5">
                    <h1 className="multi-heading-text ">
                      <span className="blue-content-heading">
                        {" "}
                        UI Design -{" "}
                      </span>{" "}
                      <span className="orange-heading-text">
                        Web and Mobile Apps
                      </span>
                    </h1>
                  </div>
                  <h5 className="font-bold">
                    When it comes to Design, All Details Matter
                  </h5>
                  <p className="mobilefont">
                    UX is driven by UI. Our bunch of UI designers at HexaMonks
                    have vast experience and expertise in the vital areas of
                    user experience. Collaboration made easy where our services
                    help you to achieve your organizational goals and determine
                    your end-user needs.
                  </p>
                </div>

                <div className="col-lg-5">
                  <div className="sr-image">
                    <img className="img21 left-radius w-100 " src={uidesign} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-5 pt-5  ">
          <div className="headline">
            <h3 className="text-center font-bold"></h3>
            <div className="bg-orange-content text-center mb-5">
              <h1 className="multi-heading-text ">
                <span className="blue-heading-text-bg"> Our </span>{" "}
                <span className="orange-heading-text">Services</span>
              </h1>
            </div>
          </div>
          <div className="row ">
            <div
              className="col-lg-6 mb-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="serviceCard ">
                <div className="icon">
                  <img src={icon3} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Web Design</h4>
                <p className="mobilefont serviceht1">
                  Get your website designed by professionals in the field.
                  Customize according to your choice. It is time to take your
                  brand to the next level by choosing us. Cross device and cross
                  browser compatibility handled under one roof for mobile
                  responsiveness. We cater to every category from basic websites
                  to complex ones. We will customize web development according
                  to your needs.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 mb-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="serviceCard">
                <div className="icon">
                  <img src={icon32} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Mobile UI Design</h4>
                <p className="mobilefont serviceht1">
                  We are one of the pioneers in Mobile UI design. We create
                  superior, state-of-the-art Mobile UI designs that suit your
                  target audience. We create intuitive designs that can be
                  customized and optimized as per your need. Our Mobile app
                  consultants drive the UI and UX of the app into an excellent
                  interface. The cutting edge technologies and latest trends are
                  explored to ensure smooth workflow of a working prototype.
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 mb-3"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="serviceCard ">
                <div className="icon">
                  <img src={icon33} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Landing Page Design</h4>
                <p className="mobilefont serviceht">
                  Do you need help in building a Landing page to suit your
                  conversion needs? Your search stops here. Interact with our
                  team of experts at HexaMonks to get your Landing Page done.
                  Get the best Landing Page designs at affordable prices. We
                  create crisp, beautiful and top notch landing pages that
                  resonate with customers’ value proposition. We create that
                  much needed CTA button with pinpoint precision which will help
                  you serve your business needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Uidesigncontent;
