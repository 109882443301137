import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import Uidesigncontent from '../Components/Services/Uidesigncontent'
import { Footer } from '../Components/Footer/Footer'

const Uidesign = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Uidesigncontent/>
        <Footer/>
    </div>
  )
}

export default Uidesign