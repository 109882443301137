import React, { useState } from "react";
import Video from "../../assets/images/optune_video_7.mp4";
import { HomeContent } from "./HomeContent";
import { HashLink } from "react-router-hash-link";
import HomeBannerImg from "../../assets/home-section/home-banner-side.png";
import Typewriter from "typewriter-effect";
export const HomeBanner = () => {
  const [loading, setLoading] = useState(true);
  const loadHandler = () => {
    setLoading(false);
  };
  return (
    <>
      <div class="bg-video-wrap2">
        <div className="home-video-content">
          <div className="container">
            <div className="row ">
              <div
                className="col-lg-10 my-auto"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                
                <h1 className="banner-heading  mb-4 font-exbold">
                  <Typewriter
                    options={{
                      // strings: ["Your Digital Transformation Partner", "Your Digital Transformation Partner"],
                      autoStart: true,
                      loop: true,
                      strings: [
                        "<span class='blue-heading font-exbold'>Your </span> Digital Transformation <span class='blue-heading font-exbold'>Partner </span>",
                        "<span class='blue-heading font-exbold'>Your </span> Digital Transformation <span class='blue-heading font-exbold'>Partner </span>",
                      ],
                    }}
                  />
                </h1>
                <p className="">
                  Are you looking for a technology partner that can deliver
                  innovative and<br></br> creative web-based solutions for your
                  business?
                </p>
                <p className="mb-0">
                  Look no further!{" "}
                  <span className="blue-heading font-exbold">Hexa Monks</span>{" "}
                  are here to cater to all your IT needs.
                </p>
                <div className="">
                  <HashLink to="/#getstarted">
                    <button type="button" className="btn mt-5 contact-btn">
                      Get Started <i class="fa-solid fa-arrow-right"></i>
                    </button>
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
      <div>
        <HomeContent />
      </div>
    </>
  );
};
