import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import Infrastructurecontent from '../Components/Services/Infrastructurecontent'

const Infrastructure = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Infrastructurecontent/>
        <Footer/>
    </div>
  )
}

export default Infrastructure