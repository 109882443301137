import React from "react";

import WWASectionImg from "../../assets/home-section/about-vortex.webp";
import FeatureImg from "../../assets/home-section/feature.webp";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import OService from "../../assets/home-section/o-services.png";
import Ser1 from "../../assets/home-section/ser-1.webp";
import Ser2 from "../../assets/home-section/ser-2.webp";
import Ser3 from "../../assets/home-section/ser-3.webp";
import Workflow from "../../assets/home-section/workflow.webp";
export const VortexContent = () => {
  return (
    <div className="content mt-5" id="getstarted">
      <div className="home-who-we-are py-5">
        <div className="container">
          <div className="row justify-content-between">
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="home-content py-3">
                <div className="bg-orange-content ">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">ABOUT </span>{" "}
                    <span className="orange-heading-text">VORTEX</span>
                  </h1>
                </div>
                <div className="mb-4">
                  <p>
                    An Orchestration framework that caters to Enterprise
                    Businesses ad-hoc and undigitized processes.
                  </p>
                </div>
                <div>
                  <p className="mb-0">
                    The platform allows for marriage between established
                    Business Process with the undigitized Business Needs.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img className="home-section-imgs" src={WWASectionImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className=" home-card-service-section  py-5">
        <div className="container">
          <div className="row justify-content-end">
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="home-content">
                <div className="bg-orange-content">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">VISION</span>{" "}
                  </h1>
                </div>
                <div className="mb-4">
                  <p>
                    To empower organizations with a versatile and innovative
                    tech framework that drives digital transformation, fosters
                    seamless collaboration of various soutions.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="home-content">
                <div className="bg-orange-content">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">MISION</span>{" "}
                  </h1>
                </div>
                <div className="mb-4">
                  <p>
                    To deliver a tech framework that empowers organizations to
                    build, deploy, and scale modern applications with ease,
                    fostering digital transformation and accelerating business
                    growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="">
        <div className="bg-orange-content mb-5 text-center">
          <h1 className="multi-heading-text">
            <span className="blue-heading-text-bg">VALUES</span>{" "}
          </h1>
        </div>
      </div>
      <div className="detailed-section  ">
        <div className="container">
          <div className="row align-items-center justify-content-end">
            <div className="col-lg-5">
              <div className="detailed-bg-color">
                <div className="bg-orange-content">
                  <h1 className="mb-3">
                    <span className="white-heading-text">VISION</span>{" "}
                  </h1>
                </div>
                <p className="text-justify fs-5 mb-0">
                  {" "}
                  To empower organizations with a versatile and innovative tech
                  framework that drives digital transformation, fosters seamless
                  collaboration of various soutions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="detailed-section-2 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="detailed-bg-color">
                <div className="bg-orange-content">
                  <h1 className="mb-3">
                    <span className="white-heading-text">MISSION</span>{" "}
                  </h1>
                </div>
                <p className="text-justify fs-5 mb-0">
                  {" "}
                  To deliver a tech framework that empowers organizations to
                  build, deploy, and scale modern applications with ease,
                  fostering digital transformation and accelerating business
                  growth.​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service-section  py-5">
        <div className="container">
          <div className="bg-orange-content text-center">
            <h1 className="multi-heading-text">
              <span className="blue-heading-text-bg">CORE </span>{" "}
              <span className="orange-heading-text">FRAMEWORK</span>
            </h1>
          </div>
          <div className="row ">
            <div className="col-lg-6 col-md-6 my-auto" data-aos="fade-right">
              <div className="">
                <img className="resources-img" src={OService} alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 my-auto" data-aos="fade-right">
              <div className="oservice-content">
                <div className="row align-items-center">
                  <div className="col-lg-12 ">
                    <div className="d-flex my-2 align-items-center">
                      <img className="ser-img" src={Ser1} alt="" />
                      <div>
                        <p className="mb-0">
                          Comprehensive User Profile and Role Management with
                          Company Profile specific Configurable Onboarding
                          Process
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 ">
                    <div className="d-flex my-2 align-items-center">
                      <img className="ser-img" src={Ser2} alt="" />
                      <div>
                        <p className="mb-0">
                          Three tier architecture built on MEAN stack, ensuring
                          application is flexible, scalable, and extensible,
                          making it a perfect framework for Cloud Hosting
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 ">
                    <div className="d-flex my-2 align-items-center">
                      <img className="ser-img" src={Ser3} alt="" />
                      <div>
                        <p className="mb-0">
                          Setup Driven Access and Data control with Admin Panel
                          segregated via Menus and Params passed through to the
                          Components
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-who-we-are pb-5">
        <div className="container">
          <div className="row justify-content-between">
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="home-content py-3">
                <div className="bg-orange-content ">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">FEATURES </span>{" "}
                  </h1>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <ul className="list-unstyled">
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> User Management
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Low Code - Form
                        Builder
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Reports Builder
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Workflow
                        Builder
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Scrumboard
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Projects
                        Management
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Multi-Tenancy
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Roles & Access
                        Management
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-unstyled">
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> ​Menu
                        Management​
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Notification
                        Workspace
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Settings Driven
                        App (Whitelabelling)
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> User Notes &
                        Files
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Events
                        Management
                      </li>
                      <li className="mb-2 feature-icon">
                        <i class="fa-solid fa-circle-check"></i> Multilingual
                        Capabilities
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img className="home-section-imgs" src={FeatureImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-who-we-are  py-5">
        <div className="container">
          <div className="row justify-content-between">
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <img className="home-section-imgs" src={Workflow} alt="" />
            </div>
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div>
                <div className="home-content pb-3">
                  <div className="bg-orange-content ">
                    <h1 className="multi-heading-text">
                      <span className="blue-content-heading"> Workflow</span>{" "}
                      <span className="orange-heading-text">Designer </span>
                    </h1>
                  </div>
                  <div>
                    <p className="">
                      Build your own Workflow to manage your business process
                      the way the are praacticed
                    </p>
                    <p className="">
                      Connect with third party systems through Configurable API
                      calls curating to your business needs
                    </p>
                  </div>
                </div>
                <div className="home-content pt-3">
                  <div className="bg-orange-content ">
                    <h1 className="multi-heading-text">
                      <span className="blue-content-heading"> Reports</span>{" "}
                      <span className="orange-heading-text">Builder</span>
                    </h1>
                  </div>
                  <div>
                    <p className="">
                      Build your own Reports to view Data the way you want right
                      from the column type to report type - be it Pivot data,
                      Simple Datatable or charts
                    </p>
                    <p className="">
                      Connect with third party systems through Configurable
                      Datasets - either as SQL queries or API calls
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className=" home-card-service-section  py-5">
        <div className="container pt-3 pb-5">
          <div className="row justify-content-center">
            <div
              className="col-lg-8"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="bg-orange-content mb-4 text-center">
                <h1 className="multi-heading-text ">
                  <span className="blue-heading-text-bg">What</span>{" "}
                  <span className="orange-heading-text">We Do</span>
                </h1>
              </div>
              <div className="mb-5 text-center">
                <p>
                  We strongly believe that you know your business best. Bearing
                  that in mind, our approach revolves around listening to you,
                  understanding your needs and helping you choose the right
                  technological solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-4 ">
              <div className="home-vertical-flex">
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <h5 class="font-bold text-end">Design and Development</h5>
                  <p className="text-end">
                    Bespoke Web Development​ | CMS-based Websites |​Dynamic Web
                    Portals | ​Integration Services​ | MVP Development
                  </p>
                </div>
                <div
                  data-aos="fade-right"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <h5 class="font-bold text-end">
                    E-Commerce, CRM &​ VRM Solutions
                  </h5>
                  <p className="text-end">
                    B2B, B2C E-Commerce Apps | CRM Portals | On-Boarding Portals
                    | VRM Application solutions |Multi-Vendor/Single Vendor
                    E-Commerce
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img className="home-section-imgs" src={WWDSectionImg} alt="" />
            </div>
            <div className="col-lg-4 ">
              <div className="home-vertical-flex">
                <div
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <h5 class="font-bold">Infrastructure management</h5>
                  <p>
                    Backup and recovery, Performance monitoring, Performance
                    tuning, Database Administration, CI/CD, Cloud Migration and
                    Management
                  </p>
                </div>

                <div
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-duration="1500"
                >
                  <h5 class="font-bold">Managed Services</h5>
                  <p>
                    Digital portfolio Management, SEO, Hybrid App development,
                    Resource Augmentation,​ Digitization Solutions, IT
                    Consultancy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
