import React from "react";
import infrastructure from "../../assets/aboutimg/infrasructure.webp";
import icon21 from "../../assets/images/icon21.webp";
import icon22 from "../../assets/images/icon22.webp";
import icon23 from "../../assets/images/icon23.webp";
import icon24 from "../../assets/images/icon24.webp";
import icon25 from "../../assets/images/icon25.webp";
import icon26 from "../../assets/images/icon26.webp";
import icon27 from "../../assets/images/icon27.webp";
import icon28 from "../../assets/images/icon28.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Infrastructurecontent = () => {
  return (
    <div>
      <div className="">
        <div className="aboutbanner mb-0 serban">
          <h4>Services</h4>
        </div>
        <div className="topcontent" id="servicepage">
          <div className="container">
            <div className="row mt-5 pt-2">
              <div
                className="col-lg-7 my-auto"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="bg-orange-content mb-5">
                  <h1 className="multi-heading-text ">
                    <span className="blue-content-heading">
                      {" "}
                      Infrastructure Management
                    </span>{" "}
                    <span className="orange-heading-text">Services</span>
                  </h1>
                </div>
                <p className="mobilefont">
                  Are you facing challenges to scale and control your IT
                  infrastructure in the ever-evolving business world? We help
                  you meet challenges, and manage IT infrastructure in data
                  center management, data administration, desktop management,
                  and web operations. Our IT infrastructure management services
                  offer a diverse suite of decisive, pliable, and established
                  infrastructure services that transfer differentiated value to
                  the client’s business. We take care of your infrastructure
                  requirements and assure optimized performance of all your
                  systems while helping you achieve your business goals. We
                  offer focused solutions in core infrastructure areas for
                  building and managing enterprise IT infrastructure.
                  <br />
                  <br />
                  With extensive experience managing IT infrastructure for
                  businesses of various sizes and verticals, we provide a
                  dependable framework of cutting-edge expertise. We provide
                  best-in-class IT platforms to deliver and manage a quality
                  experience to multiple users in business eco-systems. By
                  following the evolving trends, we help customers through
                  innovative offerings on the themes of big data,
                  digitalization, infrastructure modernization, and process
                  harmonization. HexaMonks ensures close partnership and
                  employee integration with clients through digitalization,
                  enhancing end user experiences with IT infrastructure
                  services. Our process harmonization solutions have assisted
                  businesses in enhancing and standardizing their IT operations
                  across their local, regional, and international business
                  units.
                </p>
              </div>
              <div
                className="col-lg-5"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="sr-image">
                  <img
                    className="left-radius w-100 h-auto pt-5 mt-5"
                    src={infrastructure}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="headline ">
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg">Our</span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
        <h5 className="font-bold text-center">
          Top Services Of Our Infrastructure Services:
        </h5>
        <div className="row ">
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon21} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Optimized performance</h4>
              <p className="mobilefont serviceht1">
                Regardless of the technology used or the goods deployed, we
                manage all your IT infrastructure and guarantee optimized
                performance for all your systems, servers, and attached devices
                to support your business objectives
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard">
              <div className="icon">
                <img src={icon22} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Data centric operations</h4>
              <p className="mobilefont serviceht1">
                We provide round-the-clock support to improve operational
                effectiveness and ensure service availability. By reducing the
                amount of work, we will help you outsource your IT
                infrastructure support needs to us. We collaborate with your
                internal team and boost the efficiency of your IT to make the
                most of your resources
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon23} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">End-user computing</h4>
              <p className="mobilefont serviceht">
                In essence, your next-generation workplace will include
                cloud-based output, proactive support, and borderless computing.
                Workplaces must be more responsive, adaptable, and
                collaborative. Changing the end-user computing experience has
                become crucial with the rise of smartphones, and cloud computing
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon24} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Architecture design</h4>
              <p className="mobilefont serviceht">
                We utilize our expertise in cloud technology and best practices
                to help organizations plan and implement a successful migration.
                We work closely with the client to assess their specific needs
                and goals and then design a customized cloud architecture that
                meets the desired requirements.
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard  ">
              <div className="icon">
                <img src={icon25} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Improve operations</h4>
              <p className="mobilefont serviceht">
                Today, network and communication infrastructure are the
                foundation for IT and business success. Through our
                infrastructure management services, we help businesses connect
                applications with customers, partners, and employees more
                effectively
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5 mt-3">
        <h5 className="font-bold text-center">Know Our Value Propositions </h5>
        <div className="row">
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon26} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Reliable</h4>
              <p className="mobilefont serviceht1">
                We can successfully manage and maintain the infrastructure of
                our clients, thanks to our diligent team of professionals. We
                have implemented strong security measures, have open lines of
                communication with clients, and are constantly reviewing and
                improving our offerings. Additionally, we have a disaster
                recovery and business continuity plan in place to ensure minimal
                disruptions in the case of an unforeseen incident
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard">
              <div className="icon">
                <img src={icon27} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Responsive</h4>
              <p className="mobilefont serviceht1">
                Our foremost priority is our customers. We are fast to act,
                whether it's catching a cybercriminal, addressing a crisis,
                experimenting with new technologies, or moving outside our
                comfort zone
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard mt-5">
              <div className="icon">
                <img src={icon28} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Flexible</h4>
              <p className="mobilefont serviceht">
                We offer the client a wide range of granular alternatives,
                including commitment models, price options, delivery location
                selection, the roadmap for infrastructural transformation, and
                acceptance of new technologies.Get in touch with us to minimize
                your operational costs and increase business agility in no time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Infrastructurecontent;
