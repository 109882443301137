import React from "react";
import { Navbar } from "../Components/Navbar/Navbar";
import { Footer } from "../Components/Footer/Footer";
import { VortexBanner } from "../Components/Vortex/VortexBanner";

export const Vortex = () => {
  return (
    <div style={{overflowX:"hidden"}}>
      <Navbar />
      <VortexBanner/>
      <Footer />
    </div>
  );
};
