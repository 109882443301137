import React from "react";
import database from "../../assets/aboutimg/database.webp";
import icon13 from "../../assets/images/icon13.webp";
import icon14 from "../../assets/images/icon14.webp";
import icon15 from "../../assets/images/icon15.webp";
import icon16 from "../../assets/images/icon16.webp";
import icon17 from "../../assets/images/icon17.webp";
import icon18 from "../../assets/images/icon18.webp";
import icon19 from "../../assets/images/icon19.webp";
import icon20 from "../../assets/images/icon20.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Databasecontent = () => {
  return (
    <div>
      <div className="">
        <div className="aboutbanner mb-0 serban">
          <h4>Services</h4>
        </div>
        <div className="topcontent" id="servicepage">
          <div className="container">
            <div className="row mt-5 pt-3">
              <div className="col-lg-7 my-auto" data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500">
                <div className="bg-orange-content mb-5">
                  <h1 className="multi-heading-text ">
                    <span className="blue-content-heading">Database Administrative</span>{" "}
                    <span className="orange-heading-text">
                     Services
                    </span>
                  </h1>
                </div>
                <p className="mobilefont">
                  Do you want to resolve all your data worries? It becomes a
                  crucial duty for enterprises to store, process, and manage an
                  enormous amount of data coming in from multiple sources and
                  formats. In the data-driven world of today, we are aware that
                  the success of your business depends on how well you
                  understand and manage your data. Therefore, we put your data's
                  administration, accessibility, and security at the center of
                  our database technology business. By utilizing our database
                  administration services, you may unleash the potential of your
                  business to expand its consumer base and achieve greater
                  success.
                  <br />
                  <br />
                  HexaMonks assists you in digital transformation and cloud
                  migration by utilizing the appropriate roadmap and ecosystem
                  collaborations. We work with you to determine the approach
                  that works for your organization-private, public, or hybrid.
                  <br />
                  <br />
                  Our team of professionals has in-depth knowledge of both open
                  and closed source databases, and they can assist you in
                  implementing the finest solutions after carefully analyzing
                  your organization's needs and professional objectives. Our
                  qualified consultants offer effective round-the-clock database
                  monitoring while assisting clients with designing, planning,
                  setup and installations, restoration, and database updates.
                  Additionally, we are well-positioned to solve the new age data
                  management demands to exploit big data and analytics in these
                  data-driven business times.
                </p>
              </div>
              <div className="col-lg-5 my-auto" data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500">
                <div className="sr-image">
                  <img className="left-radius w-100 h-auto" src={database} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="headline">
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg">Our</span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
        <h5 className="font-bold text-center">
          Why Choose Us For Acquiring Database Administrative Services?{" "}
        </h5>
        <div className="row ">
          <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard  ">
              <div className="icon">
                <img src={icon13} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Round-the-clock coverage</h4>
              <p className="mobilefont serviceht1">
                Our experts work in shifts and are ready to assist, inspect, and
                mitigate any concern at any point of the day. This frees the IT
                resources while enabling them to work on more essential business
                initiatives. You can contact us via email, telephone, or chat to
                know more about our data administration services. Reach out to
                us and we are here to help you.
              </p>
            </div>
          </div>
          <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard">
              <div className="icon">
                <img src={icon14} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">
                Achieve enhanced business efficiency
              </h4>
              <p className="mobilefont serviceht1">
                By helping businesses achieve improved business with data
                consolidation, we ensure streamlined business processes and
                increased ROI
              </p>
            </div>
          </div>
          <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon15} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Top quality database experts</h4>
              <p className="mobilefont serviceht">
                Our global team of expert professionals brings their years of
                experience and specialization to the company, offering companies
                the broadest administration base possible. The team takes care
                of administration and optimizes your database, allowing you to
                focus on more critical company concerns
              </p>
            </div>
          </div>
          <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon16} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Architecture design</h4>
              <p className="mobilefont serviceht">
                We utilize our expertise in cloud technology and best practices
                to help organizations plan and implement a successful migration.
                We work closely with the client to assess their specific needs
                and goals and then design a customized cloud architecture that
                meets the desired requirements.
              </p>
            </div>
          </div>
          <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon17} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Client focused delivery</h4>
              <p className="mobilefont serviceht">
                Irrespective of whether you are looking for day-to-day support,
                a minor upgrade, or a vital migration to the cloud, we offer a
                client-focused delivery to meet your needs. We add optimum value
                to your corporate information by acquiring maximum information
              </p>
            </div>
          </div>
         
        </div>
        <div className="container pt-5 mt-3">
        <h5 className="font-bold text-center">
            Know our top database administrative services{" "}
          </h5>
          <div className="row ">
            <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
              <div className="serviceCard ">
                <div className="icon">
                  <img src={icon18} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Backup and recovery</h4>
                <p className="mobilefont serviceht1">
                  We use state-of-the-art tools for automating backups and
                  restoring data in the event of a disaster or data loss. We
                  create backup and recovery plans and procedures based on
                  industry-led practices. Additionally, we use existing backups
                  to restore lost information to the system. This may contain
                  functions like incremental backups, point-in-time recovery,
                  and disaster recovery
                </p>
              </div>
            </div>
            <div  className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
              <div className="serviceCard">
                <div className="icon">
                  <img src={icon19} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Performance monitoring and tuning</h4>
                <p className="mobilefont serviceht1">
                  We also offer tools for tracking database performance,
                  locating potential slowdown causes, and fixing them. Features
                  like real-time monitoring, indexing, and query optimization
                  may be included in this. We also make configuration changes to
                  the software or add additional hardware capacity
                </p>
              </div>
            </div>
            <div
             className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300"
            >
              <div className="serviceCard mt-5">
                <div className="icon">
                  <img src={icon20} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Security management</h4>
                <p className="mobilefont serviceht">
                  We offer security management during database administrative
                  services and implement a variety of security measures such as
                  encryption, firewalls, access control, auditing, etc.
                  Additionally, we also consult audit logs to inspect and assess
                  any irregularity or security breach
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Databasecontent;
