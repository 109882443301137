import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import Migrationcontent from '../Components/Services/Migrationcontent'

const Cloudpage = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Migrationcontent/>
        <Footer/>

    </div>
  )
}

export default Cloudpage