import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import Applicationdevcontent from '../Components/Services/Applicationdevcontent'

const ApplicationDevelopment = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Applicationdevcontent/>
        <Footer/>
    </div>
  )
}

export default ApplicationDevelopment