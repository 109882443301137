import React from "react";
import webdevimg from "../../assets/aboutimg/webdevimg.webp";
import icon3 from "../../assets/images/icon3.webp";
import icon6 from "../../assets/images/icon6.webp";
import icon7 from "../../assets/images/icon7.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Webdevelopementcontent = () => {
  return (
    <div>
      <div className="">
        <div className="aboutbanner mb-0 serban">
          <h4>Services</h4>
        </div>
        <div className="topcontent" id="servicepage">
          <div className="container">
            <div className="row ">
              <div
                className="col-lg-6 my-auto"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="bg-orange-content mb-5">
                  <h1 className="multi-heading-text ">
                    <span className="blue-content-heading"> Web</span>{" "}
                    <span className="orange-heading-text">Development</span>
                  </h1>
                </div>

                <h5 className="font-bold">
                  We just code your Websites, We code your world!
                </h5>
                <p className="mobilefont">
                  We understand the importance of a fast, clean, and
                  native-app-like user experience in the era of SPAs and PWAs.
                  Therefore, at HexaMonks, we use cutting-edge technologies, and
                  proven methodologies like HexaMonks and Scrum to make the app
                  scalable and snappier. We constantly experiment and update our
                  skills in Web development. We implement the ideas constantly
                  which are right for your business. We make your web more
                  attractive and smooth to run and operate.
                </p>
              </div>

              <div
                className="col-lg-6"
                data-aos="zoom-in"
                data-aos-delay="100"
                data-aos-duration="1500"
              >
                <div className="sr-image">
                  <img className="left-radius w-100 h-auto" src={webdevimg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="headline">
          <h3 className="text-center font-bold"></h3>
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg"> Our </span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon3} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Custom Web application development</h4>
              <p className="mobilefont serviceht1">
                HexaMonks uses top-notch web technologies to build your
                customized web application. According to the requirements, it is
                scalable and flexible. We build the next gen custom web
                applications. Our in house programmers make sure that you have
                the latest web app at your disposal. We make it easily fixable
                and perform according to your company’s needs.
              </p>
             
            </div>
          </div>
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard">
              <div className="icon">
                <img src={icon6} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">CMS development</h4>
              <p className="mobilefont serviceht1">
                With us, We use our in-house knowledge for CMS development. It
                will be done by keeping your tastes and choices in the mind. We
                develop the CMS keeping the user in mind. We develop such strong
                frameworks and basics that are ultimately useful for the end
                user. These are designed by analyzing the latest trends in the
                market.
              </p>
             
            </div>
          </div>
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon7} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">E-commerce development</h4>
              <p className="mobilefont serviceht">
                In the era of digitalization, all merchants and businesses are
                switching to e-commerce. We offer premium e-commerce development
                at optimum prices to make your business function in the digital
                landscape effectively. We take care of feeding information to
                integrating payment gateways to your e- commerce sites. We will
                help you build an e- commerce site that is both flexible and
                scalable in the current market.
              </p>
             
            </div>
          </div>
         
        </div>
      </div>
      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webdevelopementcontent;
