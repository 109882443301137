import React from "react";
import CustomModal from "./CustomModal";
import Thankyou from '../../assets/images/thank.webp'
const ThankYouModal = ({ open, setOpen }) => {

    return (
        <div>
            <CustomModal
                open={open}
                onClickOutside={() => {
                    setOpen(false);
                }}
            >
                <div className="modal-content">
                    <div className="close-btn">
                        <i 
                        className="fa-regular fa-circle-xmark"
                        onClick={()=>setOpen(false)}
                        ></i>
                    </div>
                    <div className="th-cnt">
                        <img src={Thankyou} alt="Thanks" />
                        <h1 className="font-bold">Thank You</h1>
                        <p>Your Details has been Successfully Submitted</p>
                        {/* <button
                            type="button"
                            className="tnk-btn btn btn-primary"
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            Back
                        </button> */}
                    </div>
                </div>
            </CustomModal>
        </div>
    );
};

export default ThankYouModal;
