import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/hexamonk-logo.webp";
import axios from "axios";
export const Footer = () => {
  const [value, setValue] = useState({});
  const [validation, setValidation] = useState({});
  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!value.Email) {
      validdateData.Email = "Please Enter Your Email Id";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validation);
    let inputvalue = {
      Email: value.Email,
    };

    let formdata = new FormData();
    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key]);
    }
    if (CheckValidation()) {
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbws4QR-Y5SMckzinJhPecTkjlSNznM72xp2LUjsn6_99j8ukjgWRVvcRcQFf_s66lnZ/exec",
          formdata
        )
        .then(() => {
          setValue({});
          e.target.reset();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  };

  const setData = (e, key) => {
    setValue({ ...value, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div className="">
      <footer className="section bg-footer">
        <div className="container ">
          <div className="row">
            <div className="col-md-3">
              <div className="">
                <a className="" href="/">
                  <img className="logo-img rounded mb-3" src={Logo} alt="" />
                </a>
                <p className="contact-info">
                  Are you looking for a
                  technology partner that can deliver innovative and creative
                  web-based solutions for your business? Look no further! HEXA MONKS are here to cater to all your IT needs.
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="">
                <h6 className="footer-heading text-uppercase text-white font-bold">
                  Quick links
                </h6>
                <ul className="list-unstyled footer-link mt-2">
                  <li>
                    <a href="">HOME </a>
                  </li>
                  <li>
                    <Link to="/Aboutus" onClick={() => window.scrollTo(0, 0)}>
                      ABOUT US
                    </Link>
                  </li>
                 
                  <li>
                    <Link to="/Contactus" onClick={() => window.scrollTo(0, 0)}>
                      CONTACT US
                    </Link>
                  </li>
                </ul>
                <h6 className="footer-heading text-uppercase text-white font-bold">
                  Call Us
                </h6>
                <p>
                <a
                  href="tel:+91 81444 19444"
                  className="contact-info text-decoration-none"
                >
                  <span className="mail">
                    <i class="fa-solid fa-phone me-1"></i>
                  </span>{" "}
                  <span>+91 81444 19444</span>
                </a>
              </p>
              </div>
            </div>
            <div className="col-md-4">
              <h6 className="footer-heading text-uppercase text-white font-bold">
                What We Do?
              </h6>
              <div>
                <div>
                  <ul className="list-unstyled footer-link mt-2">
                    <li>
                      <Link to="/ApplicationDevelopment">
                        APPLICATION DEVELOPMENT AND IMPLEMENTATION{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/WebDevelopement">WEB DEVELOPMENT </Link>
                    </li>
                    <li>
                      <Link to="/CloudMigration">
                        CLOUD MIGRATION AND MANAGEMENT SERVICES{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/DatabaseAdministrative">
                        DATABASE ADMINISTRATIVE SERVICES
                      </Link>
                    </li>
                    <li>
                      <Link to="/InfrastructureManagement">
                        INFRASTRUCTURE MANAGEMENT SERVICES
                      </Link>
                    </li>
                    <li>
                      <Link to="/Branding">BRANDING</Link>
                    </li>
                    <li>
                      <Link to="/UIDesign">UI DESIGN</Link>
                    </li>
                    <li>
                      <Link to="/DigitalMarketing">DIGITAL MARKETING</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 ">
              <h6 className="footer-heading text-uppercase text-white font-bold">
                Email
              </h6>
              <p>
                <a
                  href="mailto:hello@hexamonks.com"
                  className="contact-info text-decoration-none"
                >
                  <span className="mail">
                    <i class="fa-solid fa-envelope me-1"></i>
                  </span>{" "}
                  <span>hello@hexamonks.com</span>
                </a>
              </p>
              <h6 className="footer-heading text-uppercase text-white font-bold">
                Address
              </h6>
              <p className=" six mb-0">
                {" "}
                <a
                  href="https://www.google.com/maps/search/4%2F410,+Semi+Basement,+North+Wing,+Venture+Park+Building,++Rajiv+Gandhi+Salai,+Chandrasekhar+Avenue,+OMR,+Thoraipakkam,++Chennai+-+600097/@12.9592395,80.2041487,14z/data=!3m1!4b1?authuser=0&entry=ttu"
                  className="contact-info text-decoration-none"
                >
                  <span className="mail me-1">
                    <i class="fa-solid fa-location-dot"></i>
                  </span>{" "}
                  <span>
                    4/410, Semi Basement, North Wing, Venture Park Building,
                    Rajiv Gandhi Salai, Chandrasekhar Avenue, OMR, Thoraipakkam,
                    Chennai - 600097
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
