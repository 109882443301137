import React from "react";
import appdevimg from "../../assets/aboutimg/appdevimg.webp";
import icon2 from "../../assets/images/icon2.webp";
import icon3 from "../../assets/images/icon3.webp";
import It from "../../assets/images/it.webp";
import icon4 from "../../assets/images/icon4.webp";
import icon5 from "../../assets/images/icon5.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Applicationdevcontent = () => {
  return (
    <div>
      <div className="">
        <div className="aboutbanner mb-0 serban">
          <h4>Services</h4>
        </div>
        <div className="topcontent" id="servicepage">
          <div className="container">
            <div className="row mt-5 pt-2">
              <div className="col-lg-7 my-auto"  data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500">
                <div className="bg-orange-content mb-5">
                  <h1 className="multi-heading-text ">
                    <span className="blue-content-heading">Application Development</span>{" "}
                    <span className="orange-heading-text">
                       and Implementation
                    </span>
                  </h1>
                </div>
                <h5 className="font-bold">
                  Wherever its required, Discover the bug out!
                </h5>
                <p className="mobilefont">
                  Do you want to automate business processes with tailored
                  software solutions? Thanks to technological advancements, it
                  is now easier to take advantage of the most recent application
                  innovations and trends to solve critical business challenges.
                  To stay ahead of the competition in the present, intensely
                  competitive business environment, business applications must
                  be continuously optimized across the board.
                  <br />
                  <br />
                  Monitoring and enhancing the systems becomes challenging for
                  most businesses with complex application ecosystems. Our
                  technical professionals utilize their in-depth operational
                  understanding of business application development, including
                  e-commerce, CRM, ERP, and service management platforms.
                </p>
              </div>
              <div className="col-lg-5 my-auto"  data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500">
                <div className="sr-image">
                  <img className="left-radius w-100 h-auto" src={appdevimg} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="headline">
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg">Our</span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
        <h5 className="font-bold text-center">Components Of Our Services</h5>
        <div className="row ">
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={It} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Enterprise application integration</h4>
              <p className="mobilefont serviceht1">
                We help develop cooperation between systems and functions,
                reduce redundancy, and streamline your business processes. By
                utilizing tools like Boomi, MuleSoft, and Microsoft, we will
                assist you in automating the transfer of data between
                applications and gadgets.
              </p>
             
            </div>
          </div>
          <div className="col-lg-6  mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard">
              <div className="icon">
                <img src={icon2} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Custom development</h4>
              <p className="mobilefont serviceht1">
                With us, you can customize an already-packaged ERP or CRM system
                to meet your specific requirements. Customization increases
                overall functioning, improves the user experience, and enhances
                user acceptance. We will work with you to maximize the return on
                investment from products like Oracle NetSuite, Microsoft D365
                (F&SC, Business Central, and Customer Engagement), Great Plains
                GP, Sage Intacct, Salesforce, and others.
              </p>
             
            </div>
          </div>
          <div className="col-lg-6  mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon3} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Web application development</h4>
              <p className="mobilefont serviceht">
                Using a web portal, we help provide a point of contact between
                your company, your clients, and your partners. Reach out to us
                to create online applications that promote information
                interchange, application process automation, or self-help.
              </p>
            
            </div>
          </div>
          <div className="col-lg-6   mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon4} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Strategy and planning</h4>
              <p className="mobilefont serviceht">
                With our advice, businesses can reevaluate their software
                development life cycle. We will meet with your personnel to
                discuss other topics as well as best practices for code
                security. We can discuss additional planning subjects like
                testing, documentation, or a comprehensive evaluation.
              </p>
             
            </div>
          </div>
          <div className="col-lg-6   mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon5} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Testing and Quality Control</h4>
              <p className="mobilefont serviceht">
                We build quality into software through our quality assurance and
                testing services. This includes performance testing, mobile
                testing, functional testing, and specialized testing.
              </p>
              
            </div>
          </div>
          
        </div>
      </div>
      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applicationdevcontent;
