import { useState } from "react";
import Team2 from "../../assets/Cardimages/team2.webp";
import axios from "axios";
import ThankYouModal from "../Modal/ThankyouModal";

const ContactContent = () => {
  const [value, setValue] = useState({});
  const [validation, setValidation] = useState({});
  const [thankYouModal, setThankYouModal] = useState(false);

  const CheckValidation = () => {
    let Status = true,
      validdateData = {};
    if (!value.Firstname) {
      validdateData.Firstname = "First Name is Required";
      Status = false;
    }
    if (!value.Lastname) {
      validdateData.Lastname = "Last Name is Required";
      Status = false;
    }
    if (!value.Email) {
      validdateData.Email = "Email is Required";
      Status = false;
    }
    if (!value.Subject) {
      validdateData.Subject = "Subject is Required";
      Status = false;
    }
    if (!value.Message) {
      validdateData.Message = "Message is Required";
      Status = false;
    }
    if (!value.Terms) {
      validdateData.Terms = "Please agree with terms and conditions";
      Status = false;
    }
    setValidation({ ...validation, ...validdateData });
    return Status;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(validation);
    let inputvalue = {
      Firstname: value.Firstname,
      Lastname: value.Lastname,
      Email: value.Email,
      Subject: value.Subject,
      Message: value.Message,
    };

    let formdata = new FormData();
    for (let key in inputvalue) {
      formdata.append(key, inputvalue[key]);
    }
    if (CheckValidation()) {
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbxY7OLpMEysvPiAWfeGzSqsCfRpODWCtFzIj3l0KvVhechZo8OSrwlB8joCDUnCHF7Q/exec",
          formdata
        )
        .then(() => {
          setValue({});
          setThankYouModal(true);
          e.target.reset();
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
  };

  const setData = (e, key) => {
    setValue({ ...value, [key]: e });
    if (validation[key]) setValidation({ ...validation, [key]: false });
  };

  return (
    <div>
      <ThankYouModal
        open={thankYouModal}
        setOpen={() => {
          setThankYouModal(false);
        }}
      />
      <div className="aboutbanner conban">
        <h4 className="font-realbold">Contact Us</h4>
      </div>
      <div className="container">
        <div
          className="row justify-content-center"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div className="col-lg-7 text-center">
            <div className="bg-orange-content text-center mb-3">
              <h1 className="multi-heading-text ">
                <span className="blue-heading-text-bg">Contact </span>{" "}
                <span className="orange-heading-text">Us</span>
              </h1>
            </div>
            <p className="">
              At Hexa Monks our mission is to empower individuals and businesses
              through innovative technology solutions that enrich lives, foster
              growth, and drive positive change.
            </p>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row g-3">
          <div className="col-lg-8">
            <div className="cnt-form home-card-service-section p-5">
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="row">
                  <div
                    className="col-lg-6 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <label htmlFor="firstname" className="mb-2 fw-bold">
                        First Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Firstname"
                        id="firstname"
                        onChange={(e) => {
                          setData(e.target.value, "Firstname");
                        }}
                      />
                    </div>
                    {validation.Firstname && (
                      <span className="validationmsg small">{validation.Firstname}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-6 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <label htmlFor="lastname" className="mb-2 fw-bold">
                        Last Name*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Lastname"
                        id="lastname"
                        onChange={(e) => {
                          setData(e.target.value, "Lastname");
                        }}
                      />
                    </div>
                    {validation.Lastname && (
                      <span className="validationmsg small">{validation.Lastname}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-6 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <label htmlFor="email" className="mb-2 fw-bold">
                        Email*
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="Email"
                        onChange={(e) => {
                          setData(e.target.value, "Email");
                        }}
                        id="email"
                      />
                    </div>
                    {validation.Email && (
                      <span className="validationmsg small">{validation.Email}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-6 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <label htmlFor="subject" className="mb-2 fw-bold">
                        Subject*
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="Subject"
                        onChange={(e) => {
                          setData(e.target.value, "Subject");
                        }}
                        id="subject"
                      />
                    </div>
                    {validation.Subject && (
                      <span className="validationmsg small">{validation.Subject}</span>
                    )}
                  </div>
                  <div
                    className="col-lg-12 mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <label htmlFor="message" className="mb-2 fw-bold">
                        Messages*
                      </label>
                      <textarea
                        type="text"
                        className="form-control"
                        id="message"
                        rows="5"
                        name="Message"
                        onChange={(e) => {
                          setData(e.target.value, "Message");
                        }}
                      />
                    </div>
                    {validation.Message && (
                      <span className="validationmsg small">{validation.Message}</span>
                    )}
                  </div>

                  <div
                    className="row justify-content-between mb-3"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="col-lg-9 d-flex flex-column">
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          className="form-check-input m-1"
                          id="check"
                          name="Terms"
                          onChange={(e) => {
                            setData(true, "Terms");
                          }}
                        />{" "}
                        <label
                          className="form-check-label fw-bold"
                          htmlFor="check"
                        >
                          I agree with terms of usage and privacy policy.
                        </label>
                      </div>
                      {validation.Terms && (
                        <span className="validationmsg small">{validation.Terms}</span>
                      )}
                    </div>
                    <div className="col-lg-12 mt-4 text-center">
                      <button className="cnt-btn" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="cnt-form2">
              <div
                className="container"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="row">
                  <div className="cnt-contact col-lg-12 col-md-4">
                    <div>
                      <i className="fa-solid fa-phone-volume "></i>
                    </div>
                    <div className="time ">
                      <h5>Call Us</h5>
                      <p>+91 81444 19444</p>
                    </div>
                  </div>
                  <div className="cnt-contact col-lg-12 col-md-4">
                    <div>
                      <i className="fa-solid fa-envelope-circle-check"></i>
                    </div>
                    <div className="time ">
                      <h5>Send Email</h5>
                      <p>hello@hexamonks.com</p>
                    </div>
                  </div>
                  <div className="cnt-contact col-lg-12 col-md-4 ">
                    <div>
                      <i className="fa-solid fa-location-dot five"></i>
                    </div>
                    <div className="time mt-2">
                      <h5>Visit Us</h5>
                      <p>
                        4/410, Semi Basement, North Wing, Venture Park Building,
                      </p>
                      <p>
                        Rajiv Gandhi Salai, Chandrasekhar Avenue, OMR,
                        Thoraipakkam,
                      </p>
                      <p> Chennai - 600097</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactContent;
