import React from "react";
import migration from "../../assets/aboutimg/migration.webp";
import icon8 from "../../assets/images/icon8.webp";
import icon9 from "../../assets/images/icon9.webp";
import icon10 from "../../assets/images/icon10.webp";
import icon11 from "../../assets/images/icon11.webp";
import icon12 from "../../assets/images/icon12.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Migrationcontent = () => {
  return (
    <div>
      <div className="">
        <div className="aboutbanner mb-0 serban">
          <h4>Services</h4>
        </div>
        <div className="topcontent" id="servicepage">
          <div className="container">
            <div className="row pt-5">
              <div className="col-lg-7 my-auto" data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500">
                <div className="bg-orange-content mb-5">
                  <h1 className="multi-heading-text ">
                    <span className="blue-content-heading">Cloud Migration </span>{" "} <br></br>
                    <span className="orange-heading-text">
                      and Management Services
                    </span>
                  </h1>
                </div>
                <p className="mobilefont">
                  The cloud computing trend has skyrocketed owing to its wide
                  array of features and business benefits. Innovative and
                  user-friendly applications, ease of access, time-saving
                  features, and qualitative outputs drive the success of cloud
                  computing while encouraging non-cloud users to take the
                  much-awaited leap. We are here to assist you in your cloud
                  journey and in resolving multiple challenges related to
                  migration
                  <br />
                  <br />
                  HexaMonks assists you in digital transformation and cloud
                  migration by utilizing the appropriate roadmap and ecosystem
                  collaborations. We work with you to determine the approach
                  that works for your organization-private, public, or hybrid.
                  <br />
                  <br />
                  We adopt a customer-centric approach, offer pre-set templates
                  and data analytics applications, ensuring smooth and effective
                  migration without causing any negative impact on the business.
                  With proven approaches and methodologies, our services
                  propagate hassle-free transformation from on-premises to cloud
                  migration and curate suitable solutions catering to your
                  business needs. Our cloud migration solutions offer an
                  analysis of the business’s objective, performs requirement
                  focused assessment while assisting in building a roadmap that
                  supports, rebuilds, and re-architect applications.
                </p>
              </div>

              <div className="col-lg-5 my-auto" data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500">
                <div className="sr-image">
                  <img className="left-radius w-100 h-auto" src={migration} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
      <div className="headline">
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg">Our</span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
        <h5 className="font-bold text-center">5 Steps For Seamless Cloud Migration</h5>
        <div className="row ">
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon8} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Deployment and testing</h4>
              <p className="mobilefont serviceht1">
                We deploy new cloud environments, stage them, and test them to
                assess their functionality, scalability, and operational
                efficiency. We use CI/CD tools to automate the process of
                testing, building, and deploying applications to the cloud. This
                allows us to make changes to the applications, without the need
                for manual intervention.
              </p>
            
            </div>
          </div>
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard">
              <div className="icon">
                <img src={icon9} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Planned strategy</h4>
              <p className="mobilefont serviceht1">
                We evaluate the current IT infrastructure to determine which
                workloads and applications would benefit the most. Additionally,
                we also assess potential dangers or difficulties connected to
                the move, followed by creating a thorough migration strategy.
              </p>
              
            </div>
          </div>
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon10} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Infra audit</h4>
              <p className="mobilefont serviceht">
                We aim to find any potential problems or restrictions that could
                affect the migration process as well as collect data on the
                infrastructure's current condition that will be required to plan
                the transfer. During an infrastructure audit, we typically
                assess the following areas: network infrastructure, server
                infrastructure, storage infrastructure, security infrastructure,
                etc.
              </p>
              
            </div>
          </div>
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon11} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Architecture design</h4>
              <p className="mobilefont serviceht">
                We utilize our expertise in cloud technology and best practices
                to help organizations plan and implement a successful migration.
                We work closely with the client to assess their specific needs
                and goals and then design a customized cloud architecture that
                meets the desired requirements.
              </p>
           
            </div>
          </div>
          <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon12} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Automation</h4>
              <p className="mobilefont serviceht">
                We use proven cloud technology solutions to create a supporting
                cloud architecture. By automating the deployment and
                configuration of cloud infrastructure, we use infrastructure as
                code (IAC), enabling businesses to automate the cloud
                architecture using code
              </p>
            
            </div>
          </div>
        </div>
      </div>
      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Migrationcontent;
