import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import Webdevelopementcontent from '../Components/Services/Webdevelopementcontent'

const Webdevelopement = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Webdevelopementcontent/>
        <Footer/>
    </div>
  )
}

export default Webdevelopement