import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import Marketingcontent from '../Components/Services/Marketingcontent'
import { Footer } from '../Components/Footer/Footer'

const Digitalmarketing = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Marketingcontent/>
        <Footer/>

    </div>
  )
}

export default Digitalmarketing