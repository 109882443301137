import React from "react";
import branding from "../../assets/aboutimg/branding.webp";
import icon29 from "../../assets/images/icon29.webp";
import icon30 from "../../assets/images/icon30.webp";
import icon31 from "../../assets/images/icon31.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Brandingcontent = () => {
  return (
    <div>
      <div className="">
        <div className="aboutbanner mb-0 serban">
          <h4>Services</h4>
        </div>
        <div className="topcontent" id="servicepage">
          <div className="container">
            <div className="row mt-5 pt-2">
              <div className="col-lg-6 my-auto">
                <div className="bg-orange-content mb-3">
                  <h1 className="multi-heading-text ">
                    <span className="blue-content-heading"> Branding</span>{" "}
                  </h1>
                </div>

                <h5 className="font-bold">
                  Wherever its required, Discover the bug out!
                </h5>
                <p className="mobilefont">
                  Make your brand stand out of the corporate crowd. Blow off
                  some steam and take our expertise in building an identity for
                  you. HexaMonks provides superior branding services that are
                  etched in the minds of customers forever.
                </p>
                <div className="button1">
                  <NavLink to="/Contactus">
                    <button className="contact-btn">Get a Proposal</button>
                  </NavLink>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="sr-image">
                  <img className=" img22 left-radius w-100 " src={branding} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="headline">
          <h3 className="text-center font-bold"></h3>
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg"> Our </span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon29} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Logo & Brand Identity</h4>
              <p className="mobilefont serviceht1">
                Give your business the needed make-up with a logo that will make
                you the polestar in the constellation of businesses. Let your
                brand do the talking for you. Get these Branding services from
                our experts at an affordable price. Logos are integral part of
                the branding. It establishes the identity of the business in the
                market. We help you in creating that identity.
                <br />
                <br />
                <span className="two">
                  Logo & Identity Design | Brand Naming | Stationery design
                </span>
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard">
              <div className="icon">
                <img src={icon30} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Graphic Design</h4>
              <p className="mobilefont serviceht1">
                Get superior graphic designs designed for your business
                requirements. We make sure that all details and elements of
                design are taken care of. We deliver the best to make your
                business the best one in the market. Color, space, typography,
                page etc. Form an integral part of the graphic design. The
                aesthetics and excellence of designing are looked upon by us
                while you handle your brand.
                <br />
                <br />
                <span className="two">
                  Marketing Literature & Collaterals | Brochures, Flyers, and
                  Leaflets | Corporate Profile{" "}
                </span>
              </p>
            </div>
          </div>
          <div
            className="col-lg-6 mb-3"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="serviceCard ">
              <div className="icon">
                <img src={icon31} alt="itservices" className="ourserv" />
              </div>
              <h4 className="font-bold">Infographics Design</h4>
              <p className="mobilefont serviceht">
                HexaMonks is capable to deliver scintillating infographics that
                you need to communicate to your stakeholders and customers.
                Explore innovation at showcasing your data with superior
                infographics. Creativity to portray the information is best
                explored by us. We communicate data by the way of art. We make
                sure that the infographics delivered to you is of top quality.
                <br />
                <br />
                <span className="two">
                  Custom infographics | Interactive infographics | Motion
                  Graphics & Video Infographic{" "}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="values ">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 my-auto">
                <p className="h1">We should get to know each other!</p>
                <p className="para3">
                  Exciting and challenging projects are driving us, but also a
                  lively exchange of experience & interests is close to our
                  hearts. If you are looking for an agency with which you want
                  to grow together, then we are the right partner.{" "}
                </p>
                <div className="button1">
                  <NavLink to="/Contactus">
                    <button
                      type="button"
                      className="btn btn-primary contact-btn"
                    >
                      Contact us
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-5">
                <img src={grouphand} className="img4" alt="grouphand" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brandingcontent;
