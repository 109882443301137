import { Link } from "react-router-dom";
import React from "react";
import design from "../../assets/aboutimg/design.webp";
import digital from "../../assets/aboutimg/digital.webp";
import cloud from "../../assets/aboutimg/cloud.webp";
import Carousal2 from "./Carousal2";

import WWASectionImg from "../../assets/home-section/section-2.webp";
import WWDSectionImg from "../../assets/home-section/section-1.webp";
import HWDSectionImg1 from "../../assets/home-section/section-3.webp";
import HWDSectionImg2 from "../../assets/home-section/section-4.webp";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export const HomeContent = () => {
  return (
    <div className="content" id="getstarted">
      {/* <div>
        <Carousal />
      </div> */}
      <div className="home-who-we-are py-5">
        <div className="container">
          <div className="row justify-content-between">
            <div
              className="col-lg-6 my-auto"
              data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <div className="home-content py-3">
                <div className="bg-orange-content ">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">Who</span>{" "}
                    <span className="orange-heading-text">We Are</span>
                  </h1>
                </div>
                <div className="mb-4">
                  <p>
                    Looking for a technology partner that can deliver innovative
                    and creative web-based solutions for your business?
                  </p>
                </div>
                <div className="mb-4">
                  <h3>
                    <span className="orange-heading-text">We are IT!!​</span>
                  </h3>
                  <h2>
                    <span className=" blue-heading-text">
                      HEXA MONKS does IT.
                    </span>
                  </h2>
                </div>
                <div>
                  <p className="mb-0">
                    With our expertise in complete end-to-end InfoTech
                    solutions, we offer a wide range of services to meet your
                    digital needs.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6"
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500"
            >
              <img className="home-section-imgs" src={WWASectionImg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="home-about-section home-card-service-section  py-5">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-lg-6 my-auto" data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500">
              <div className="home-content">
                <div className="bg-orange-content">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">About</span>{" "}
                    <span className="orange-heading-text">Us</span>
                  </h1>
                </div>
                <div className="mb-4">
                  <p>
                    "HexaMonks Infotech is a startup business operating in
                    Chennai, India, with an employee base of 15+ professionals
                    and the ability to tap into a network of shared resources
                    consisting of 50+ independent professionals who can be
                    onboarded on an as-needed basis. This ensures scalability,
                    allowing us to deliver high-quality and curated solutions."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-our-goal py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 my-auto" data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="1500">
              <div className="home-content">
                <div className="bg-orange-content">
                  <h1 className="multi-heading-text">
                    <span className="blue-heading-text-bg">Our</span>{" "}
                    <span className="orange-heading-text">Goal</span>
                  </h1>
                </div>
                <div className="mb-4">
                  <p>
                    Our goal is to become a leading provider of enterprise
                    software solutions that transform the way businesses operate
                    and individuals live their lives. We aim to achieve this by
                    continuously improving our products and services, expanding
                    our expertise, and delivering measurable value to our
                    clients.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" home-card-service-section  py-5">
        <div className="container pt-3 pb-5">
          <div className="row justify-content-center">
            <div className="col-lg-8" data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500">
              <div className="bg-orange-content mb-4 text-center">
                <h1 className="multi-heading-text ">
                  <span className="blue-heading-text-bg">What</span>{" "}
                  <span className="orange-heading-text">We Do</span>
                </h1>
              </div>
              <div className="mb-5 text-center">
                <p>
                  We strongly believe that you know your business best. Bearing
                  that in mind, our approach revolves around listening to you,
                  understanding your needs and helping you choose the right
                  technological solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-lg-4 ">
              <div className="home-vertical-flex">
                <div data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500">
                  <h5 class="font-bold text-end">Design and Development</h5>
                  <p className="text-end">
                    Bespoke Web Development​ | CMS-based Websites |​Dynamic Web
                    Portals | ​Integration Services​ | MVP Development
                  </p>
                </div>
                <div data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500">
                  <h5 class="font-bold text-end">
                    E-Commerce, CRM &​ VRM Solutions
                  </h5>
                  <p className="text-end">
                    B2B, B2C E-Commerce Apps | CRM Portals | On-Boarding Portals
                    | VRM Application solutions |Multi-Vendor/Single Vendor
                    E-Commerce
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4" data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500">
              <img className="home-section-imgs" src={WWDSectionImg} alt="" />
            </div>
            <div className="col-lg-4 ">
              <div className="home-vertical-flex">
                <div data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500">
                  <h5 class="font-bold">Infrastructure management</h5>
                  <p>
                    Backup and recovery, Performance monitoring, Performance
                    tuning, Database Administration, CI/CD, Cloud Migration and
                    Management
                  </p>
                </div>

                <div data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500"> 
                  <h5 class="font-bold">Managed Services</h5>
                  <p>
                    Digital portfolio Management, SEO, Hybrid App development,
                    Resource Augmentation,​ Digitization Solutions, IT
                    Consultancy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-what-we-do py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8" data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-duration="1500">
              <div className="bg-orange-content mb-5 text-center">
                <h1 className="multi-heading-text ">
                  <span className="blue-heading-text-bg">How </span>{" "}
                  <span className="orange-heading-text">We Do</span>
                </h1>
              </div>
              <div className="mb-4 text-center">
                <p>
                  We believe in collaboration and leveraging technology into the
                  business ecosystem and embed it as an essential part of their
                  value.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-5" data-aos="fade-right"
              data-aos-delay="100"
              data-aos-duration="1500">
              <img className="home-section-imgs" src={HWDSectionImg1} alt="" />
            </div>
            <div className="col-lg-5" data-aos="fade-left"
              data-aos-delay="100"
              data-aos-duration="1500">
              <img className="home-section-imgs" src={HWDSectionImg2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="client-content home-card-service-section">
        <div className="container">
          <div className="row">
            <div
              className="client col-md-10 col-lg-10"
              data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
            >
              <div className="bg-orange-content  mb-3">
                <h1 className="multi-heading-text ">
                  <span className="blue-heading-text-bg">Our </span>{" "}
                  <span className="orange-heading-text">Services</span>
                </h1>
              </div>
              <p className="">
                HexaMonks Solutions is a leading system integration and
                management consulting firm that offers application and
                technology modernization solutions and services. Formed by
                industry leading experts with more than 2 decades of experience
                in consulting, development and implementation we create value
                propositions for clients and support in accelerating their
                growth .
              </p>
            </div>
          </div>
          <div className="homecsl">
            <OwlCarousel
              className="owl-theme"
              loop
              autoplay={true}
              items="1"
              dots={false}
              smartSpeed={2000}
              nav={true}
              margin={20}
              center={true}
              autoplayTimeout={5000}
              responsive={{
                360: {
                  items: "1",
                },
                414: {
                  items: "1",
                },
                670: {
                  items: "2",
                },
                992: {
                  items: "3",
                },
                1200: {
                  items: "3",
                },
              }}
            >
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={design}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Application Development and Implementation
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        Wherever its required, Discover the bug out! Do you want
                        to automate business processes with tailored software
                        solutions?
                      </p>
                      <Link to="/ApplicationDevelopment">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={digital}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Web Development
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        We just code your Websites, We code your world! We
                        understand the importance of a fast, clean, and
                        native-app-like user experience in the era of SPAs and
                        PWAs.
                      </p>
                      <Link to="/WebDevelopement">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={cloud}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Cloud Migration and Management Services
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        The cloud computing trend has skyrocketed owing to its
                        wide array of features and business benefits. Innovative
                        and user-friendly applications.
                      </p>
                      <Link to="/CloudMigration">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={design}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Database Administrative Services
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        Do you want to resolve all your data worries? It becomes
                        a crucial duty for enterprises to store, process, and
                        manage an enormous amount of data coming in from
                        multiple sources and formats.
                      </p>
                      <Link to="/DatabaseAdministrative">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={digital}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Infrastructure Management Services
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        Are you facing challenges to scale and control your IT
                        infrastructure in the ever-evolving business world? We
                        help you meet challenges, and manage IT infrastructure
                        in data center management.
                      </p>
                      <Link to="/InfrastructureManagement">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={cloud}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Branding
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        Wherever its required, Discover the bug out! Make your
                        brand stand out of the corporate crowd. Blow off some
                        steam and take our expertise in building an identity for
                        you.
                      </p>
                      <Link to="/Branding">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={design}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        UI Design - (Web and Mobile Apps)
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        When it comes to Design, All Details Matter UX is driven
                        by UI. Our bunch of UI designers at Agile IT have vast
                        experience and expertise in the vital areas of user
                        experience.
                      </p>
                      <Link to="/UIDesign">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" my-2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <div className="servicecard">
                  <div className="cardicon">
                    <div className="cardContent ">
                      <img
                        src={digital}
                        className="img8 ser-card-img"
                        alt="design"
                      />
                      <h5 className="font-bold text-start ser-carousel-heading-height">
                        Digital Marketing
                      </h5>
                      <p className="mb-0 text-start small ser-carousel-para-height">
                        Measuring Customer Satisfaction @ Smiles per hour Agile
                        IT connect you to a network of qualified and trained
                        Digital Marketing (Marketing Services) providers in
                        Chennai.
                      </p>
                      <Link to="/DigitalMarketing">
                        <button>Know More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </div>
    </div>
  );
};
