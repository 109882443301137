import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/home-section/logo.png";
import Cycle from "../../assets/images/cycle.webp";
import StarIcon from "../../assets/images/star-icon.webp";
import Secure from "../../assets/images/sec.webp";
import Mule from "../../assets/images/mul.webp";
import Web from "../../assets/images/web.webp";
import Bus from "../../assets/images/bus.webp";
import Clo from "../../assets/images/clo.webp";
import Mweb from "../../assets/images/mweb.webp";
import Cus from "../../assets/images/cus.webp";
import { Link } from "react-router-dom";

export const Navbar = ({ seract, proact }) => {
  const ProductTabs = [
    "Custom Developement",
    "Cloud Management",
    "Database Services",
    "Infrastructure Services",
    "Other services",
  ];
  const [closedr, setClosedr] = useState(false);
  const [closedr2, setClosedr2] = useState(false);
  // const [proact2, setProact2] = useState("")
  // const [seract2, setSeract2] = useState("")
  const ServiceSubTabs = [
    {
      img: Secure,
      heading: "Custom Developement",
    },
    {
      img: Mule,
      heading: "Cloud Management",
    },
    {
      img: Clo,
      heading: "Database Servicess",
    },
    {
      img: Cus,
      heading: "Infrastructure Services",
    },
    {
      img: Cus,
      heading: "Other services",
    },
  ];
  const SubProductTabs = [
    {
      img: Secure,
      heading: "IT Security & Compliance",
      content:
        "Our IT security compliance products are tools and solutions designed to help organizations meet and adhere to regulatory requirements and industry standards related to information security.",
    },
    // {
    //   heading: "MuleSoft support",
    //   content: "Our managed and consulting service model helps fill in the knowledge gap in IT teams.",
    // },
    // {
    //   heading: "Cloud Migration",
    //   content: "Our Cloud migration service helps clients to reduce IT costs and improving performance with security and convenience advantage too.",
    // },
    // {
    //   heading: "Custom Development",
    //   content: " Tailored food delivery solutions crafted to meet your unique business needs and scale effortlessly. ",
    // },
    // {
    //   heading: "Quality Analysis",
    //   content: " Ensure top-notch service with our comprehensive quality analysis. Evaluate every aspect from order accuracy to delivery speed to guarantee customer satisfaction.",
    // }
  ];
  const [selectedProductTabs, setSelectedProductTabs] = useState(
    ProductTabs[0]
  );
  const [selectSubserviceTabs, setSelectSubserviceTabs] = useState(
    ServiceSubTabs[0]
  );
  const [selectSubProductTabs, setSelectSubProductTabs] = useState(
    SubProductTabs[0]
  );

  useEffect(() => {
    // Get all dropdown items
    const dropdownItems = document.querySelectorAll(".service");
    // Remove 'active' class from all dropdown items
    dropdownItems.forEach((item) => {
      item.classList.remove("active");
    });

    // // Add 'active' class to the first dropdown item
    dropdownItems[0].classList.add("active");
  }, []);

  const ProductDetailsTabs = [
    {
      heading: "Food Aggregation",
      content:
        " Demo Description - t non deserunt ullamco est sit aliqua amet sint.",
    },
    {
      heading: "Food Aggregation",
      content:
        " Demo Description - t non deserunt ullamco est sit aliqua amet sint.",
    },
    {
      heading: "Food Aggregation",
      content:
        " Demo Description - t non deserunt ullamco est sit aliqua amet sint.",
    },
  ];

  const [navSize, setnavSize] = useState("5rem");
  const [navColor, setnavColor] = useState("transparent");
  const listenScrollEvent = () => {
    window.scrollY > 10 ? setnavColor("#fff") : setnavColor("transparent");
    window.scrollY > 10 ? setnavSize("6rem") : setnavSize("5rem");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <div>
      <div
        className="bg-light-nav-top pt-1 pb-1"
        style={{
          backgroundColor: navColor,
          height: navSize,
          transition: "all 1s",
        }}
      >
        <div className="home-navbar">
          <nav className="navbar navbar-expand-lg navbar-light navbar-fixed-top p-0">
            <div className="container">
              <a className="navbar-brand" href="/">
                <img className="logo-img" src={Logo} alt="" />
              </a>
              <button
                className="navbar-toggler "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#main_nav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>

              <div
                className={`collapse navbar-collapse justify-content-end lh-lg ${
                  closedr2 ? "d-none" : ""
                }`}
                id="main_nav"
              >
                <ul className="navbar-nav p-3 p-md-0 me-3">
                  <li className="nav-item">
                    <NavLink className="nav-link font-bold" to="/">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link font-bold" to="/AboutUs">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown d-lg-block d-none ktm-mega-menu">
                    <a
                      className={`nav-link dropdown-toggle font-bold ${seract}`}
                      href="#"
                      data-bs-toggle="dropdown"
                      id="navbarDropdownItem"
                    >
                      Services
                    </a>

                    <div
                      className={`dropdown-menu mega-menu p-3 ${
                        closedr ? "d-none" : ""
                      }`}
                    >
                      <div className="container mega-menu-inner">
                        <div className="row">
                          <div className="col-lg-3">
                            <div className="">
                              <div className="tabs border-b">
                                {ProductTabs.map((tab, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className={
                                        selectedProductTabs === tab
                                          ? "dropdownitem  service tab active "
                                          : "tab service"
                                      }
                                      // onMouseOver={() => {
                                      //   setSelectedProductTabs(tab);
                                      // }}
                                      onClick={() => {
                                        setSelectedProductTabs(tab);
                                      }}
                                    >
                                      <span>{tab}</span>
                                      <span>
                                        <i class="fa-solid fa-arrow-right"></i>
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            {selectedProductTabs === "Custom Developement" && (
                              <div className="row pt-4">
                                <div className="col-lg-4">
                                  <>
                                    <div>
                                      <NavLink
                                        style={{ textDecoration: "none" }}
                                        to="/ApplicationDevelopment"
                                        className="mb-0 cursor-pointer"
                                        onClick={() => {
                                          setClosedr(true);
                                          setTimeout(() => {
                                            setClosedr(false);
                                          }, 1000);
                                        }}
                                      >
                                        <div className="card box1">
                                          <h6 class="card-title font-bold">
                                            APPLICATION DEVELOPMENT AND
                                            IMPLEMENTATION
                                          </h6>
                                        </div>
                                      </NavLink>
                                    </div>
                                  </>
                                </div>
                                <div className="col-lg-4">
                                  <div>
                                    <>
                                      <div>
                                        <NavLink
                                          style={{
                                            textDecoration: "none",
                                            color: "bule",
                                          }}
                                          to="/WebDevelopement"
                                          className=" cursor-pointer"
                                          onClick={() => {
                                            setClosedr(true);
                                            setTimeout(() => {
                                              setClosedr(false);
                                            }, 1000);
                                          }}
                                        >
                                          <div className="card box">
                                            <h6 class="card-title font-bold">
                                              WEB DEVELOPMENT
                                            </h6>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedProductTabs === "Cloud Management" && (
                              <div className="row pt-4">
                                <div className="col-lg-4">
                                  <div>
                                    <>
                                      <div>
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to="/CloudMigration"
                                          className="mb-0 cursor-pointer"
                                          onClick={() => {
                                            setClosedr(true);
                                            setTimeout(() => {
                                              setClosedr(false);
                                            }, 1000);
                                          }}
                                        >
                                          <div className="card box">
                                            <h6 class="card-title font-bold">
                                              CLOUD MIGRATION AND MANAGEMENT
                                              SERVICES
                                            </h6>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedProductTabs === "Database Services" && (
                              <div className="row pt-4">
                                <div className="col-lg-4">
                                  <div>
                                    <>
                                      <div>
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to="/DatabaseAdministrative"
                                          className="mb-0 cursor-pointer"
                                          onClick={() => {
                                            setClosedr(true);
                                            setTimeout(() => {
                                              setClosedr(false);
                                            }, 1000);
                                          }}
                                        >
                                          <div className="card box">
                                            <h6 class="card-title font-bold">
                                              DATABASE ADMINISTRATIVE SERVICES
                                            </h6>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedProductTabs ===
                              "Infrastructure Services" && (
                              <div className="row pt-4">
                                <div className="col-lg-4">
                                  <div>
                                    <>
                                      <div>
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to="/InfrastructureManagement"
                                          className="mb-0 cursor-pointer"
                                          onClick={() => {
                                            setClosedr(true);
                                            setTimeout(() => {
                                              setClosedr(false);
                                            }, 1000);
                                          }}
                                        >
                                          <div className="card box1">
                                            <h6 class="card-title font-bold">
                                              INFRASTRUCTURE MANAGEMENT SERVICES
                                            </h6>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            )}
                            {selectedProductTabs === "Other services" && (
                              <div className="row pt-4">
                                <div className="col-lg-4">
                                  <>
                                    <div>
                                      <NavLink
                                        style={{ textDecoration: "none" }}
                                        to="/Branding"
                                        className="mb-0 cursor-pointer"
                                        onClick={() => {
                                          setClosedr(true);
                                          setTimeout(() => {
                                            setClosedr(false);
                                          }, 1000);
                                        }}
                                      >
                                        <div className="card box">
                                          <h6 class="card-title font-bold">
                                            BRANDING
                                          </h6>
                                        </div>
                                      </NavLink>
                                    </div>
                                  </>
                                </div>

                                <div className="col-lg-4">
                                  <div>
                                    <>
                                      <div>
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to="/UIDesign"
                                          className="mb-0 cursor-pointer"
                                          onClick={() => {
                                            setClosedr(true);
                                            setTimeout(() => {
                                              setClosedr(false);
                                            }, 1000);
                                          }}
                                        >
                                          <div className="card box">
                                            <h6 class="card-title font-bold">
                                              UI DESIGN
                                            </h6>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </>
                                  </div>
                                </div>
                                <div className="col-lg-4">
                                  <div>
                                    <>
                                      <div>
                                        <NavLink
                                          style={{ textDecoration: "none" }}
                                          to="/DigitalMarketing"
                                          className="mb-0 cursor-pointer"
                                          onClick={() => {
                                            setClosedr(true);
                                            setTimeout(() => {
                                              setClosedr(false);
                                            }, 1000);
                                          }}
                                        >
                                          <div className="card box">
                                            <h6 class="card-title font-bold">
                                              DIGITAL MARKETING
                                            </h6>
                                          </div>
                                        </NavLink>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item dropdown  d-lg-none  d-block">
                    <a
                      className="nav-link font-bold dropdown-toggle"
                      href="#"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="false"
                    >
                      Services
                    </a>

                    <ul className="dropdown-menu mob-dr-1">
                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Custom Developement
                        </a>
                        <ul className="dropdown-menu mobnav">
                          <li>
                            <NavLink
                              to="/ApplicationDevelopment"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Application Developement & Implementation
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/WebDevelopement"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Web Developement
                            </NavLink>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Cloud Management
                        </a>
                        <ul className="dropdown-menu mobnav">
                          <li>
                            <NavLink
                              to="/CloudMigration"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Cloud Migration & Management Services
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Database Services
                        </a>
                        <ul className="dropdown-menu mobnav">
                          <li>
                            <NavLink
                              to="/DatabaseAdministrative"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Database Administrative Services
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Infrastructure Services
                        </a>
                        <ul className="dropdown-menu mobnav">
                          <li>
                            <NavLink
                              to="/InfrastructureManagement"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Infrastructure Management Services
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="dropdown-toggle nav-link"
                          href="#"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="false"
                        >
                          Other services
                        </a>
                        <ul className="dropdown-menu mobnav">
                          <li>
                            <NavLink
                              to="/Branding"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Branding
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/UIDesign"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              UI Design
                            </NavLink>
                          </li>
                          <li>
                            <NavLink
                              to="/DigitalMarketing"
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setClosedr2(true);
                                setTimeout(() => {
                                  setClosedr2(false);
                                }, 1000);
                              }}
                            >
                              Digital Marketing
                            </NavLink>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link font-bold" to="/Vortex">
                      Vortex
                    </NavLink>
                  </li>
                  <li className="nav-item my-auto">
                    <NavLink to="/Contactus">
                      <button
                        type="button"
                        className="btn btn-primary contact-btn"
                      >
                        Contact us
                      </button>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};
