import React from "react";
import img444 from "../../assets/aboutimg/img444.webp";
import inquire from "../../assets/aboutimg/inquire.webp";
import innovate from "../../assets/aboutimg/innovate.webp";
import implement from "../../assets/aboutimg/implement.webp";
import ourgoal from "../../assets/aboutimg/ourgoal.webp";
import ourmission from "../../assets/aboutimg/ourmission.webp";
import ourvission from "../../assets/aboutimg/ourvission.webp";
import teamhead from "../../assets/aboutimg/teamhead.webp";
import grouphand from "../../assets/aboutimg/grouphand.webp";
import { NavLink } from "react-router-dom";

const AboutBanner = () => {
  return (
    <div>
      <div className=" aboutbanner" data-aos="fade-up" data-aos-delay="300">
        <h4 className="font-bold">About Us</h4>
      </div>
      <div className="mt-5" data-aos="fade-up" data-aos-delay="300">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 mt-3">
              <div className="bg-orange-content mb-5">
                <h1 className="multi-heading-text ">
                  <span className="blue-heading-text-bg">About </span>{" "}
                  <span className="orange-heading-text">Us</span>
                </h1>
              </div>
              <h5 className="font-bold">
                Thoughts , insights, and stories from HexaMonks
              </h5>
              <p className="text-justify-content">
                HexaMonks Solutions is a leading system integration and
                management consulting firm that offers application and
                technology modernization solutions and services. Formed by
                industry leading experts with more than 2 decades of experience
                in consulting, development and implementation we create value
                propositions for clients and support in accelerating their
                growth .
                <br />
                We harness innovations and contribute towards the enhancement of
                technology, agility, efficiency and transformation of companies.
                We provide businesses with real value with a combination of
                state of the art methodologies and adaptive integration
                technologies, keeping in mind their business strategies to pave
                way for their inevitable success stories
              </p>
            </div>
            <div className="col-lg-6">
              <img src={img444} alt="image" />
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 home-card-service-section">
        <div  className=" container">
        <div className="valuecards ">
          <div className="values" data-aos="fade-up" data-aos-delay="300">
            <div>
              <div className="bg-orange-content text-center mb-3">
                <h1 className="multi-heading-text ">
                  <span className="blue-heading-text-bg">Our </span>{" "}
                  <span className="orange-heading-text">Values</span>
                </h1>
              </div>
              <h5 className="font-bold">Guided by Integrity and Purpose</h5>
              <p>
                At FutureSphere, we are driven by a strong set of values that
                shape every aspect of our business, ensuring transparency,
                ethical practices, and a shared commitment to making a positive
                impact.
              </p>
            </div>
          </div>
          <div className="row" data-aos="fade-up" data-aos-delay="300">
            <div className="col-lg-4 col-sm-6 mb-3">
              <div className="about-card-sec">
                <div className="inquire">
                  <img
                    src={inquire}
                    className="img1"
                    alt="Inquire"
                  />
                </div>
                <p className="h3">Inquire</p>
                <p className="para1">
                  Our team of industry experts abreast of the market leading
                  technologies and applications understand the business
                  requirements and perform a technology gap analysis on current
                  systems and applications
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6  mb-3">
              <div className="about-card-sec">
                <div className="inquire">
                  <img
                    src={innovate}
                    className="img1 "
                    alt="Inquire"
                  />
                </div>
                <p className="h3">Innovate</p>
                <p className="para1">
                  Browsing our stack of applications and market leading
                  technologies, our team creates a fully-customized solution,
                  made just for your business. Our experts improvise current
                  applications with tailor made enhancement, upgradation and
                  add-ons to enhance efficiency and meet your business
                  requirements
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6  mb-3">
              <div className="about-card-sec">
                <div className="inquire">
                  <img
                    src={implement}
                    className="img1  "
                    alt="Inquire"
                  />
                </div>
                <p className="h3">Implement</p>
                <p className="para1">
                  Our dedicated team of professionals implement the framed
                  solutions in record time. ensuring adherence to the scope of
                  the business. Our team’s agility, reactivity, and execution
                  efficiency set us apart from the competition in reducing real
                  time implementation to a less complex process
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6  mb-3">
              <div className="about-card-sec">
                <div className="inquire">
                  <img
                    src={ourgoal}
                    className="img1  "
                    alt="Inquire"
                  />
                </div>
                <p className="h3">Our Goals</p>
                <p className="para1">
                  Our goal would inspire our thoughts to bring a stop for all
                  our clients for all their solutions. We aspire to act as
                  digital agency with all branches of services.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6  mb-3">
              <div className="about-card-sec">
                <div className="inquire">
                  <img
                    src={ourmission}
                    className="img1  "
                    alt="Inquire"
                  />
                </div>
                <p className="h3">Our Mission</p>
                <p className="para1">
                  Our mission lies in delivering world class application
                  technologies which streamlines processes, reduce costs, and
                  increase productivity with impeccable methodologies supporting
                  organizations to effectively manage the information technology
                  infrastructure and operations
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6  mb-3">
              <div className="about-card-sec">
                <div className="inquire">
                  <img
                    src={ourvission}
                    className="img1  "
                    alt="Inquire"
                  />
                </div>
                <p className="h3">Our Vission</p>
                <p className="para1">
                  To empower businesses across the globe operate their full
                  potential through enhanced technologies and applications
                </p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <div className="values ">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 my-auto">
              <p className="h1">We should get to know each other!</p>
              <p className="para3">
                Exciting and challenging projects are driving us, but also a
                lively exchange of experience & interests is close to our
                hearts. If you are looking for an agency with which you want to
                grow together, then we are the right partner.{" "}
              </p>
              <div className="button1">
                <NavLink to="/Contactus">
                  <button type="button" className="btn btn-primary contact-btn">
                    Contact us
                  </button>
                </NavLink>
              </div>
            </div>
            <div className="col-lg-5">
              <img src={grouphand} className="img4" alt="grouphand" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutBanner;
