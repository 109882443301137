import React from 'react'
import { Navbar } from '../Components/Navbar/Navbar'
import { Footer } from '../Components/Footer/Footer'
import Brandingcontent from '../Components/Services/Brandingcontent'

const Branding = () => {
  return (
    <div>
        <Navbar seract="active"/>
        <Brandingcontent/>
        <Footer/>
    </div>
  )
}

export default Branding