import React from "react";
import digitalmarketing from "../../assets/aboutimg/digitalmarketing.webp";
import icon36 from "../../assets/images/icon36.webp";
import icon34 from "../../assets/images/icon34.webp";
import icon35 from "../../assets/images/icon35.webp";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import grouphand from "../../assets/aboutimg/grouphand.webp";

const Marketingcontent = () => {
  return (
    <div>
      <div>
        <div className="">
          <div className="aboutbanner mb-0 serban">
            <h4>Services</h4>
          </div>
          <div className="topcontent" id="servicepage">
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-7 my-auto">
                  <div className="bg-orange-content mb-5">
                    <h1 className="multi-heading-text ">
                      <span className="blue-content-heading">Digital</span>{" "}
                      <span className="orange-heading-text"> Marketing</span>
                    </h1>
                  </div>
                  <h4>Measuring Customer Satisfaction @ Smiles per hour</h4>
                  <p className="mobilefont">
                    HexaMonks connect you to a network of qualified and trained
                    Digital Marketing (Marketing Services) providers in Chennai.
                    Here are a few reasons to use QuikrEasy to connect with
                    Digital Marketing (Marketing Services) providers: - A huge
                    selection of top Digital Marketing (Marketing Services)
                    service providers in Chennai - Connect instantly with
                    Digital Marketing (Marketing Services) service
                    providers-Service quotes from various Digital Marketing
                    (Marketing Services) providers before choosing one that
                    suits your requirements the best - Avail the best deals
                    within your budget.
                  </p>
                </div>

                <div className="col-lg-5">
                  <div className="sr-image">
                    <img
                      className=" img20 left-radius w-100 "
                      src={digitalmarketing}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="headline">
           
          <h3 className="text-center font-bold"></h3>
          <div className="bg-orange-content text-center mb-5">
            <h1 className="multi-heading-text ">
              <span className="blue-heading-text-bg"> Our </span>{" "}
              <span className="orange-heading-text">Services</span>
            </h1>
          </div>
        </div>
          <div className="row ">
            <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
              <div className="serviceCard ">
                <div className="icon">
                  <img src={icon34} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Competition Analysis</h4>
                <p className="mobilefont serviceht1">
                  Wanna capture valuable leads from your targeted group of
                  audience, then picking social media as your marketing platform
                  will be the right decision
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
              <div className="serviceCard">
                <div className="icon">
                  <img src={icon35} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Thorough Strategization</h4>
                <p className="mobilefont serviceht1">
                  The video is known to boost your conversion and sales. By just
                  promoting a product video it is proven that a business can get
                  80% more conversions.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-3" data-aos="fade-up" data-aos-delay="300">
              <div className="serviceCard ">
                <div className="icon">
                  <img src={icon36} alt="itservices" className="ourserv" />
                </div>
                <h4 className="font-bold">Desired Results</h4>
                <p className="mobilefont serviceht">
                  Email marketing is now utilized by thousands of businesses
                  across the globe for the growth of their business.
                </p>
              </div>
            </div>

          </div>
        </div>
        <div className="values ">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 my-auto">
                <p className="h1">We should get to know each other!</p>
                <p className="para3">
                  Exciting and challenging projects are driving us, but also a
                  lively exchange of experience & interests is close to our
                  hearts. If you are looking for an agency with which you want
                  to grow together, then we are the right partner.{" "}
                </p>
                <div className="button1">
                  <NavLink to="/Contactus">
                    <button
                      type="button"
                      className="btn btn-primary contact-btn"
                    >
                      Contact us
                    </button>
                  </NavLink>
                </div>
              </div>
              <div className="col-lg-5">
                <img src={grouphand} className="img4" alt="grouphand" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketingcontent;
