import Mvp from '../../assets/images/mvp11.png'
import M from '../../assets/images/quality.webp'

const Qualitydev = () => {
       return (
        <div className='mvpcontent'>
            <div className='mvpbanner'>
                <img src={Mvp} alt='banner' className='w-100 bannr-img' />
                <div className='mvpcontentx'>
                    <p className='h1'>Quality Analysis  </p>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2 mt-4'>
                    <div className='col-lg-6 order-lg-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6 order-lg-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>1</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>QA Hub</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Rigorous quality checks to ensure accurate orders and timely deliveries.</li>
                                    <li className='mb-4'> Comprehensive evaluations to maintain food safety standards and customer satisfaction </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>2</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Delivery Standards</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Timely delivery commitments to ensure meals reach you promptly. </li>
                                    <li className='mb-4'>Stringent quality checks guaranteeing that your orders arrive fresh and accurate </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6 order-lg-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6 order-lg-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>3</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Quality Check </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Thorough inspections to maintain food freshness and safety standards. </li>
                                    <li className='mb-4'>Comprehensive assessments guarantee order accuracy and customer satisfaction. </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>4</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Service Evaluation </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Detailed assessments to ensure prompt and efficient delivery service. </li>
                                    <li className='mb-4'>Comprehensive evaluations of food quality and customer satisfaction to enhance the overall experience </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6 order-lg-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6 order-lg-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>5</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Order Integrity </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Robust systems to guarantee accuracy and completeness of orders. </li>
                                    <li className='mb-4'>Rigorous checks to maintain the integrity of every delivery, ensuring customer satisfaction </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>6</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Delivery Metrics</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Track delivery times and accuracy to ensure prompt and reliable service. </li>
                                    <li className='mb-4'>Monitor performance indicators to optimize delivery efficiency and customer satisfaction </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6 order-lg-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6 order-lg-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>7</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Performance Analysis </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Evaluate delivery speed and accuracy to enhance service efficiency. </li>
                                    <li className='mb-4'>Analyze customer feedback and satisfaction metrics to drive continuous improvement </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>8</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Customer Satisfaction </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Prioritizing promptness and accuracy to ensure delighted customers. </li>
                                    <li className='mb-4'>Continuous feedback integration to tailor our services to meet your needs and preferences </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6 order-lg-2 mvpblockimg' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6 order-lg-1' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>9</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Service Assurance </p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Commitment to reliable and timely deliveries, ensuring peace of mind. </li>
                                    <li className='mb-4'>Rigorous quality checks to uphold standards and guarantee customer satisfaction.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row my-lg-5 my-2'>
                    <div className='col-lg-6  mvpblockimg1' data-aos="fade-up" data-aos-delay="300">
                        {/* <div className='mvpblockimg'> */}
                        <img src={M} alt="mvpblocks" className='mvp-img' />
                        {/* </div> */}
                    </div>
                    <div className='col-lg-6' data-aos="fade-up" data-aos-delay="300">
                        <div className='d-flex'>
                            <div className='sticker'>
                                <p className='number m-0'>10</p>
                            </div>
                            <div className=''>
                                <p className='intro h1 font-bold mb-4'>Quality Control</p>
                                <ul className='mvplist'>
                                    <li className='mb-4'>Comprehensive checks to maintain food freshness, accuracy, and safety. </li>
                                    <li className='mb-4'>Stringent measures to ensure consistent service excellence and customer satisfaction </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Qualitydev