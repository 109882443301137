import React from 'react'
import { Footer } from "../Components/Footer/Footer";
import { Navbar } from "../Components/Navbar/Navbar";
import Qualitydev from '../Components/Products/Qualitydev';


const Qualitypage = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar proact="active" />
      <Qualitydev />
      <Footer />
    </div>
  )
}

export default Qualitypage